<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="svg-social" viewBox="0 0 32 32" fill="none">
        <g class="normal">
            <circle cx="16" cy="16" r="16" fill="black" fill-opacity="0.6" />
            <path
                d="M11.3644 23V12.5514H8.08734V23H11.3644ZM9.72585 8C8.66902 8 7.9792 8.70493 8.0005 9.6457C7.97866 10.543 8.66902 11.2694 9.70455 11.2694C10.7821 11.2694 11.4725 10.543 11.4725 9.64518C11.4507 8.70493 10.7821 8 9.72585 8ZM13.9953 23H17.2723V16.9743C17.2723 16.6756 17.316 16.3548 17.4023 16.1619C17.6175 15.5645 18.1565 14.9444 19.0626 14.9444C20.2478 14.9444 20.723 15.8632 20.723 17.2096V23H24V16.8035C24 13.7269 22.3833 12.316 20.2265 12.316C18.4804 12.316 17.4023 13.3208 16.9708 13.9827H16.9058L16.7551 12.5514H13.909C13.9521 13.4701 13.9953 14.5812 13.9953 15.8847V23Z"
                fill="white"
            />
        </g>
        <g class="colored">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <circle cx="16" cy="16" r="16" fill="#0176B4" />
                <path
                    d="M11.3644 23V12.5514H8.08734V23H11.3644ZM9.72585 8C8.66902 8 7.9792 8.70493 8.0005 9.6457C7.97866 10.543 8.66902 11.2694 9.70455 11.2694C10.7821 11.2694 11.4725 10.543 11.4725 9.64518C11.4507 8.70493 10.7821 8 9.72585 8ZM13.9953 23H17.2723V16.9743C17.2723 16.6756 17.316 16.3548 17.4023 16.1619C17.6175 15.5645 18.1565 14.9444 19.0626 14.9444C20.2478 14.9444 20.723 15.8632 20.723 17.2096V23H24V16.8035C24 13.7269 22.3833 12.316 20.2265 12.316C18.4804 12.316 17.4023 13.3208 16.9708 13.9827H16.9058L16.7551 12.5514H13.909C13.9521 13.4701 13.9953 14.5812 13.9953 15.8847V23Z"
                    fill="white"
                />
            </svg>
        </g>
    </svg>
</template>
