<template>
    <div class="newsletter">
        <div class="newsletter-title">Subscribe to our newsletter</div>
        <div class="newsletter-form-container">
            <AForm
                ref="newsletterForm"
                :model="formState.formInline"
                name="newsletter-form"
                :rules="rules"
                layout="horizontal"
            >
                <div class="newsletter-form-inputs">
                    <AFormItem
                        v-for="formItem in formState.formConfig"
                        :key="formItem.key"
                        :name="formItem.key"
                        :label="formItem.label"
                        :class="formItem.key"
                    >
                        <AInput
                            v-if="formItem.inputType === 'text'"
                            v-model:value="formState.formInline[formItem.key]"
                            :placeholder="formItem.tPlaceholder"
                            :type="'text'"
                            :maxlength="99"
                            autocomplete="off"
                        />
                    </AFormItem>
                </div>
                <div class="newsletter-form-submit">
                    <CommonComponentsButtonLine ref="submitBtn" borderless button-text="Subscribe" @click="onSubmit" />
                </div>
            </AForm>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref, reactive, Ref } from 'vue';
    import type { Rule, FormInstance } from 'ant-design-vue/es/form';
    import { postSubscribeNews } from '~~/composables/useHubspot.ts';
    import i18n from '@/locales';
    import message from '~~/antd//message/adapter';
    import { useRouter } from 'vue-router';

    const router = useRouter();
    const { t } = i18n.global;

    type hsFormKey = 'firstname' | 'lastname' | 'email';

    const newsletterForm = ref<FormInstance | null>(null);
    const submitBtn = ref<HTMLButtonElement | null>(null);
    const isSubmitting = ref<boolean>(false);

    /* eslint-disable  */
    const validateEmail = async (_rule: Rule, value: string) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value === '') {
            return Promise.reject(message.error('Please complete the Email Address field.'));
        } else if (!re.test(String(value).toLowerCase())) {
            return Promise.reject(message.error(t('global_validate_message_4')));
        } else {
            return Promise.resolve();
        }
    };

    // 表单信息
    const formState = reactive<{
        labelCol: {
            span: number;
        };
        wrapperCol: {
            span: number;
        };
        formInline: {
            firstname: string;
            lastname: string;
            email: string;
        };
        isFinished: boolean;
        formConfig: Array<{
            key: hsFormKey;
            dataType: string;
            inputType: string;
            name: string;
            label: string;
            tPlaceholder: string;
        }>;
    }>({
        labelCol: { span: 9 },
        wrapperCol: { span: 16 },
        formInline: {
            firstname: '',
            lastname: '',
            email: '',
        },
        isFinished: false,
        // inputType 区分页面上输入框的样式
        formConfig: [
            {
                key: 'firstname',
                dataType: 'text',
                inputType: 'text',
                name: '姓',
                label: 'First Name',
                tPlaceholder: 'Please input your first name',
            },
            {
                key: 'lastname',
                dataType: 'text',
                inputType: 'text',
                name: '名',
                label: 'Last Name',
                tPlaceholder: 'Please input your last name',
            },
            {
                key: 'email',
                dataType: 'email',
                inputType: 'text',
                name: '邮箱',
                label: 'Email Address',
                tPlaceholder: 'Please input your email address',
            },
        ],
    });

    // 校验规则，设置是否必需
    const rules: Record<string, Rule[]> = {
        email: [{ required: true, type: 'email', validator: validateEmail, trigger: 'blur' }],
    };

    const genParams = () => {
        const params = Object.keys(formState.formInline).map((key) => {
            return {
                name: key,
                value: formState.formInline[key] || '',
            };
        });
        return params.filter((item) => !!item);
    };

    // 表单提交回调
    const onSubmit = () => {
        (newsletterForm as Ref<FormInstance>).value
            .validate()
            .then(async () => {
                if (isSubmitting.value) return;
                isSubmitting.value = true;

                postSubscribeNews({
                    submittedAt: new Date().getTime(),
                    fields: genParams(),
                    context: {
                        pageUri: window.location.href,
                    },
                })
                    .then((res) => {
                        submitSuccess();
                    })
                    .catch((err) => {
                        isSubmitting.value = false;
                        message.error(err._data.inlineMessage);
                    });
            })
            .catch((error) => {});
    };

    const submitSuccess = () => {
        message.success(t('global_apply_trial_submit_success'));
        isSubmitting.value = false;
        newsletterForm.value?.resetFields();
        formState.isFinished = false;
        router.push('/thanks');
    };

    const checkScrollSelector = (child: HTMLElement) => {
        // 获取所有下拉框
        const dropdown = document.querySelectorAll('.ant-select-dropdown');
        for (let i = 0; i < dropdown.length; i++) {
            const element = dropdown[i];
            // 如果下拉框包含子元素，则返回true
            if (element.contains(child)) return true;
        }
        // 否则返回false
        return false;
    };
</script>

<style lang="less" scoped>
    @input-border-color: rgba(256, 256, 256, 0.8);
    @placeholder-font-color: rgba(256, 256, 256, 0.3);
    .custom-input {
        background: none;
        border: none;
        border-bottom: 1px solid @input-border-color;
        box-shadow: none;
        border-radius: 0;
    }
    .newsletter {
        width: 100%;
        padding: 50px 0 40px 0;
        background-color: #262626;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 48px;

        @media (max-width: @screen-mobile-width) {
            padding: 40px 0;
            gap: 26px;
            background-color: #161616;
        }
        .newsletter-title {
            color: #fff;
            font-family: Montserrat;
            font-size: .convert(48px) [ @vw];
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            @media (max-width: @screen-mobile-width) {
                font-size: .convert(20px) [ @vw-mobile];
            }
        }
        .newsletter-form-container {
            .newsletter-form-inputs {
                width: .convert(1200px) [ @vw];
                display: grid;
                grid-template-columns: repeat(2, 1fr) 1.25fr;
                gap: .convert(80px) [ @vw];
                @media (max-width: 900px) {
                    width: .convert(1360px) [ @vw];
                }

                @media (max-width: @screen-mobile-width) {
                    width: .convert(320px) [ @vw-mobile];
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    transform: translate(-10px);
                }
            }
            .newsletter-form-submit {
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }

            :deep(.ant-form) {
                font-family: 'HarmonyOS Sans SC';

                .ant-form-item {
                    .ant-input {
                        color: #fff;
                        .custom-input();

                        &:hover,
                        &:focus {
                            .custom-input();
                        }

                        &::placeholder {
                            color: @placeholder-font-color;
                        }
                        &:focus::-webkit-input-placeholder {
                            opacity: 0;
                            transition: all 0.3s ease;
                        }
                    }

                    &-label {
                        width: fit-content;
                        padding-right: 5px;
                        text-align: left;
                        label {
                            color: rgba(255, 255, 255, 0.8);
                            &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
                                &::after {
                                    display: inline-block;
                                    margin-right: 4px;
                                    font-size: 14px;
                                    font-family: SimSun, sans-serif;
                                    line-height: 1;
                                    content: '*';
                                }
                                &::before {
                                    display: none;
                                }
                            }

                            &::after {
                                content: '';
                            }
                        }
                    }
                }
                .ant-form-item-has-error {
                    .ant-input:focus {
                        box-shadow: none;
                    }
                    .ant-input-group-addon {
                        border-color: @input-border-color;
                    }
                }

                @media (max-width: @screen-mobile-width) {
                    .ant-form-item {
                        display: flex;
                        flex-wrap: nowrap !important;
                        flex-direction: row;
                        gap: 15px;

                        .ant-form-item-label {
                            flex-shrink: 1;
                            max-width: 120px;
                            width: .convert(77px) [ @vw-mobile];
                            text-align: right;
                        }
                        .ant-form-item-control {
                            flex-shrink: 1;
                        }
                    }
                }
            }
        }
    }
</style>
