<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="svg-social" viewBox="0 0 44 44" fill="none">
        <g class="normal">
            <circle cx="22" cy="22" r="22" fill="black" fill-opacity="0.6" />
            <path
                d="M31.9515 16.6349C31.9446 16.3309 31.9504 16.0281 31.9481 15.7241C31.5494 15.7192 31.1522 15.6759 30.7618 15.595C30.0978 15.4515 29.4642 15.1924 28.8899 14.8293C28.8534 14.7882 28.8168 14.7436 28.7688 14.715C27.986 14.2396 27.3746 13.5162 26.954 12.7094C26.6805 12.1874 26.4816 11.6296 26.3632 11.0523H26.3403C26.2775 10.7082 26.2477 10.3588 26.2512 10.009C24.8821 10.009 23.5142 10.0101 22.1463 10.0078C22.1394 11.994 22.1531 13.9813 22.1371 15.9664V26.3682C22.1294 26.9442 21.9777 27.5091 21.6958 28.0115C21.414 28.5139 21.011 28.9378 20.5235 29.2446C19.5178 29.8903 18.1544 29.9531 17.1088 29.3657C16.7201 29.1377 16.3705 28.8487 16.0734 28.5098C15.6399 28.0117 15.367 27.3943 15.2906 26.7384C15.2154 26.0631 15.3373 25.3805 15.6414 24.7728C15.8231 24.4174 16.078 24.102 16.3831 23.846C16.8802 23.4255 17.4916 23.1546 18.1236 23.0083C18.6527 22.8803 19.2241 22.8769 19.7407 23.0586V21.0073C19.7349 20.6073 19.7544 20.2073 19.7315 19.8073C19.7178 19.4874 19.7292 19.1651 19.7258 18.8451C17.999 18.6024 16.2417 18.971 14.758 19.8873C13.7404 20.5162 12.8857 21.3765 12.2633 22.3981C11.5808 23.5159 11.2026 24.7927 11.1662 26.1019C11.1213 27.5272 11.4828 28.9359 12.2084 30.1634C12.7477 31.0789 13.4753 31.8693 14.3432 32.4822L14.3992 32.529C15.2157 33.1031 16.1402 33.5055 17.1168 33.7118C17.8089 33.8541 18.5159 33.9109 19.2218 33.881C20.6228 33.8451 21.9795 33.3826 23.1108 32.5553C23.9752 31.9158 24.6978 31.1041 25.233 30.1714C25.861 29.0982 26.2362 27.8961 26.3301 26.6562C26.3552 26.319 26.3403 25.9819 26.3392 25.6448C26.3205 23.1116 26.3125 20.5784 26.3152 18.0451C27.9635 19.2127 29.9362 19.8345 31.9561 19.8233C31.9549 18.7605 31.9629 17.6977 31.9526 16.6349H31.9515Z"
                fill="white"
            />
        </g>
        <g class="colored">
            <circle cx="22" cy="22" r="22" fill="white" />
            <path
                d="M13.5581 26.9206C13.5603 24.9607 14.3399 23.0817 15.7257 21.6958C17.1116 20.3099 18.9906 19.5304 20.9506 19.5282V23.9634C20.1666 23.9643 19.4151 24.2761 18.8607 24.8305C18.3064 25.3848 17.9946 26.1364 17.9937 26.9203C17.9937 28.3732 18.7794 29.9362 20.5046 29.9362C20.6194 29.9362 23.3162 29.9098 23.3162 27.6161V10.8047H28.0426C28.0452 11.8646 28.4676 12.8804 29.2174 13.6295C29.9673 14.3787 30.9834 14.8002 32.0434 14.8017L32.0394 19.229C30.5303 19.2406 29.0472 18.836 27.753 18.0596L27.7512 27.6161C27.7512 32.004 24.0173 34.3715 20.504 34.3715C16.5445 34.3724 13.5581 31.1688 13.5581 26.9206Z"
                fill="#FF4040"
            />
            <path
                d="M12.375 25.7409C12.3772 23.781 13.1568 21.902 14.5426 20.5161C15.9285 19.1302 17.8075 18.3507 19.7674 18.3485V22.7837C18.9835 22.7846 18.2319 23.0965 17.6776 23.6508C17.1233 24.2051 16.8115 24.9567 16.8106 25.7406C16.8106 27.1935 17.5963 28.7565 19.3215 28.7565C19.4363 28.7565 22.1331 28.7301 22.1331 26.4364V9.625H26.8595C26.8621 10.6849 27.2845 11.7007 28.0343 12.4499C28.7842 13.199 29.8003 13.6205 30.8603 13.622L30.8563 18.0493C29.3472 18.0609 27.8641 17.6563 26.5699 16.8799L26.5681 26.4364C26.5681 30.8243 22.8342 33.1918 19.3209 33.1918C15.3614 33.1928 12.375 29.9891 12.375 25.7409Z"
                fill="#00F5FF"
            />
            <path
                d="M19.7676 19.6219C14.9944 20.2275 11.2189 26.3154 15.1359 31.7937C18.7545 34.7533 26.3666 33.0321 26.5774 26.6287L26.5722 16.8869C27.874 17.6511 29.357 18.0519 30.8666 18.0478V14.6222C29.979 14.3225 29.2117 13.7447 28.6785 12.9745C27.8959 12.4598 27.3152 11.6905 27.0345 10.7969H23.3178L23.3153 27.6695C23.3119 30.0278 19.3792 30.8677 18.3809 28.5789C15.8807 27.3836 16.446 22.841 19.7728 22.7914L19.7676 19.6219Z"
                fill="black"
            />
        </g>
    </svg>
</template>
